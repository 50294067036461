import React from 'react'
import css from "./Hire.module.scss"
import reactdev from "../../../assets/image/react.png"
import { Helmet } from 'react-helmet';

const ReactJS = () => {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Hire React.js Developer - Expertise in Web & Mobile App Development | YourCompany</title>
        <meta name="description" content="Looking to hire a React.js developer for your web or mobile application development? Choose from our pool of talented professionals with years of experience in handling client requirements. Contact us now!" />
      </Helmet>
    <div>
        <div className={css.container}>
            <div className={css.section1}>
                <div className={css.title}>HIRE REACT JS DEVELOPER
</div>
                <div className={css.heading}>Choose an expert, intermediate or a novice as your project demands.
</div>
                <div className={css.text}>At Reveation, we have good pool of talented and skilled professionals where companies can choose from. These skilled professionals come with rich years of Experience in handling requirement of clients pertaining to web or mobile application development.

</div>
            </div>
            <div className={css.section2}>
<img src={reactdev} alt="ReactJS developer" title="ReactJS developer" className={css.img} />
            </div>
        </div>
    </div>
    </>
  )
}

export default ReactJS