import React from "react";
import "./contact.css";
import { MdEmail } from "react-icons/md";
import { ImMap } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";

const Contactcard = () => {
  return (
    <div className="contact-cards-div">
      <section>
        <div class="testimotionals">
          <div class="card">
            <div class="layer"></div>
            <div class="content">
              <div class="details">
                <span>
                  <MdEmail className="card-icon" />
                </span>
                <h2>
                  {" "}
                  E mail <br />{" "}
                  <span>
                    <a>info@reveation.com</a>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="testimotionals">
          <div class="card">
            <div class="layer"></div>
            <div class="content">
              <div class="details">
                <span className="card-icon">
                  <BsFillTelephoneFill />
                </span>

                <h2>
                  {" "}
                  Contact <br />{" "}
                  <span>
                    <a>+91 9989773391</a>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="testimotionals">
          <div class="card">
            <div class="layer"></div>
            <div class="content">
              <div class="details">
                <span>
                  <ImMap className="card-icon" />
                </span>

                <h2>
                  {" "}
                  Address <br />{" "}
                  <span>
                    <a>
                      A- 516, Ratnaakar 9 Square, Opp ITC Narmada, Keshavbag,
                      Vastrapur, Ahmedabad- 380015.
                    </a>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contactcard;
