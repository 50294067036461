import React from 'react'
import css from "./Hire.module.scss"
import manual from "../../../assets/image/manual-tester.png"
import { Helmet } from 'react-helmet';

const Manual = () => {
  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>Hire Manual Tester - Expert Bug Finders | YourCompany</title>
        <meta name="description" content="Looking to hire a manual tester to find all bugs, faults, and flaws in your project? Our experts ensure thorough testing of various functional aspects to meet clients' requirements. Contact us now!" />
      </Helmet>    <div>
        <div className={css.container}>
            <div className={css.section1}>
                <div className={css.title}>HIRE MANUAL TESTER

</div>
                <div className={css.heading}>Find all bugs, faults and flaws in your project or product.

</div>
                <div className={css.text}>Picking the experts which can help to run the process through thick and thin. They make sure the project is well tested with various functional aspects and is up to mark as per clients’ requirements.



</div>
            </div>
            <div className={css.section2}>
<img src={manual} alt="Manual Tester"  title="Manual Tester" className={css.img} />
            </div>
        </div>
    </div></>

  )
}

export default Manual