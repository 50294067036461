import React from 'react';
import './Home.css'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { Divider } from '@mui/material';
import image from '../../assets/image/slider-1.png';
import clutchlogo from '../../assets/logo/Clutch-logo.png';
import goodfirms from '../../assets/logo/goodfirmslogo.jpg';
import card1 from '../../assets/logo/rental-property.png';
import card2 from '../../assets/logo/project-management.png';
import card3 from '../../assets/logo/hrms-management.png';
import card4 from '../../assets/logo/healthcare.png';
import card5 from '../../assets/logo/sales-distribution.png';
import card6 from '../../assets/logo/market-place.png';
import js from '../../assets/logo/js.png';
import reactjs from '../../assets/logo/react-js.png';
import sap from '../../assets/logo/sap.png';
import qa from '../../assets/logo/qa.png';
import automation from '../../assets/logo/automation.png';
import resources from '../../assets/logo/resource.png';
import execution from '../../assets/logo/execution.png';
import specialist from '../../assets/logo/specialists.png';
import tech from '../../assets/logo/tech-brain.png';
import project from '../../assets/logo/project-done.png';
import happy from '../../assets/logo/happpy-clients.png';
import client from '../../assets/logo/retention.png';
import coffee from '../../assets/logo/coffee.png';
import discussion from '../../assets/logo/discussion.png';
import define from '../../assets/logo/define.png';
import design from '../../assets/logo/design.png';
import development from '../../assets/logo/development.png';
import delivery from '../../assets/logo/deliver.png';
import arrow from '../../assets/logo/arrow.png'
import Home4 from './Home4/Home4';
import Slider from '../../components/Slider/Slider';
import ContactForm from '../ContactUs/contactform';

function Home() {
    return (
        <div className='MainHome'>
            <div className='Home-1'>
                <div className='Home'>
                    <div className='left'>
                        <b>Custom Software Development</b>
                        <p>We develop custom software that helps your business do more.
                            Our expert development teams build solutions that make business processes more efficient,
                            improve customer experience and generate more profit for our clients.
                        </p>
                        <div className='Endbuttons'>
                            <div className='button1'>
                                <div className='button-1-1'>
                                    <p>REVIEWED ON</p>
                                    <img src={clutchlogo} alt='' />
                                </div>
                                <div className='button-1-2'>
                                    <p>3 REVIEWS</p>
                                </div>
                            </div>
                            <div className='button2'>
                                <div className='button-2-1'>
                                    <strong>EXCELLENT</strong>
                                    <img src={goodfirms} alt='' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='right'>
                        <img src={image} alt='Custom Software Development' title='Custom Software Development' />
                    </div>
                </div>
            </div>

            {/* Section-2 */}
            <div className='Home-2'>
                <div className='Field'>
                    <strong>Fields we expertise in</strong>
                    <div className='dev'>
                        <b className='devider1'></b>
                        <b className='devider'></b>
                    </div>
                    <p>We are ideal technology partner who understand business and able to implement digital solution
                        efficiently. The best Tech solution will help to reduce costs, improve efficiencies, reduce
                        costs and adding value and boost revenue.</p>
                </div>
                <div className='Cards'>
                    <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
                        <div className='CardsImg'>
                            <img src={card1} alt='' />
                        </div>
                        <h4>Rental Property Management</h4>
                        <p>Are u looking for software to manage rental property! You have come at right place.
                            Hire our services to manage the rental property management through our customised
                            solutions.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
                        <div className='CardsImg1'>
                            <img src={card2} alt='' />
                        </div>
                        <h4>Project Management</h4>
                        <p>Facing difficulty in handling project management process? Not any more. We provide custom
                            project management solutions as per the project needs.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
                        <div className='CardsImg'>
                            <img src={card3} alt='' />
                        </div>
                        <h4>HRMS Management Software</h4>
                        <p>HRMS can help the growing business easily collect, store and organise its employee
                            information on one place. Get our services to get the right fit for your business needs.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
                        <div className='CardsImg1'>
                            <img src={card4} alt='' />
                        </div>
                        <h4>Wellness Center Management Solutions</h4>
                        <p>Do your clients need software to manage the Wellness centre? We are here to help you
                            in one stop solution to all day-to-day operation needs.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
                        <div className='CardsImg'>
                            <img src={card5} alt='' />
                        </div>
                        <h4>Sales & Distribution Solutions</h4>
                        <p>We can help you to get software which drives sales and grows the business.
                            Smart Business solutions encompasses Web and Mobile Apps . Contact us for more queries.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#1c7200' }}>
                        <div className='CardsImg1'>
                            <img src={card6} alt='' />
                        </div>
                        <h4>Market Place Software</h4>
                        <p>Having B2B or B2C market plan. Looking for a software solution which is perfect for your
                            needs! Connect us for a free demo.</p>
                    </div>
                </div>
            </div>
            {/* Section-3 */}
            <div className='Home-2'>
                <div className='Field'>
                    <strong>Our experts for hire</strong>
                    <div className='dev'>
                        <b className='devider1'></b>
                        <b className='devider'></b>
                    </div>
                    <p>We have found a simple solution to a peculiar problem. Finding the right talent for your
                        software project requirements is no more a task to lay down your precious time on. We do the
                        hiring and management of the resources and you keep focus on better and bigger tasks at hand.
                        Choose the best the industry has to offer. A wide range of talent is available for hire at
                        Reveation.</p>
                </div>
                <div className='Cards3'>
                    <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
                        <img src={js} alt='' />
                        <h4>Hire React JS Developer</h4>
                        <p>We acquire dedicated software programmers who create user friendly, client specific applications.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
                        <img src={reactjs} alt='' />
                        <h4>Hire React Native Developer</h4>
                        <p>Expert developers who can create robust mobility solutions for the clients in different industries.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
                        <img src={sap} alt='' />
                        <h4>Hire Node JS Developer</h4>
                        <p>Let us help you build the dream SAP ABAP Developer team you need to succeed.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
                        <img src={sap} alt='' />
                        <h4>Hire UI UX Designer</h4>
                        <p>Let us help you build the dream SAP ABAP Developer team you need to succeed.</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
                        <img src={qa} alt='' />
                        <h4>Hire Manual Tester</h4>
                        <p>Manual or functional testingexpert takes responsibilities to identify issues and make sure
                            that software is delivered as per customer expectations. Quality does matter!</p>
                    </div>
                    <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
                        <img src={automation} alt='' />
                        <h4>Hire Automation Tester</h4>
                        <p>Selenium is used for functional automation testing, Jmeter for Performance testing, burp
                            suite for security testing, let us know how can we help you with allocating right talent.
                            We have all.</p>
                    </div>
                </div>
            </div><br /><br /><br />

            {/* Section-4 */}
            <Home4 />

            {/* Section-5 */}
            <div className='Home-5'>
                <div className='Maincomp'>
                    <div className='left'>
                        <div className='up'>
                            <h6>WHY REVEATION IS YOUR GO TO TEAM</h6>
                            <strong>We serve your purpose</strong>
                            <p>We believe in continuous improvement We cater to the right business strategies
                                and ability to automate the business processes and solutions that makes a
                                difference.</p>
                            <div className='button'>Contact Now</div>
                        </div>
                        <Divider sx={{ width: '95%', margin: '6% 0%' }} />
                        <div className='down'>
                            <h3>Why Choose Us</h3>
                            <ul style={{ listStyle: 'none' }}>
                                <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h5>We are preferred software solution provider for the clients.</h5></li>
                                <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h5>We have rich years of experience to understand any business domain.</h5></li>
                                <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <h5>Ability to automate business processes and solutions that makes difference.</h5></li>
                            </ul>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='wrap'>
                            <div className='cards'>
                                <div className='card1'>
                                    <img src={resources} alt='' />
                                    <h4>Right Talent</h4>
                                    <p>Right and skilful resources available for projects</p>
                                </div>
                                <div className='card2'>
                                    <img src={execution} alt='' />
                                    <h4>Executions</h4>
                                    <p>Successful projects deployment case studies</p>
                                </div>
                                <div className='card2'>
                                    <img src={specialist} alt='' />
                                    <h4>Specialists Only</h4>
                                    <p>Hire team with right aptitude and Can-Do attitude</p>
                                </div>
                                <div className='card1'>
                                    <img src={tech} alt='' />
                                    <h4>Tech Brains</h4>
                                    <p>Exposure in various technologies and updates on trends</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Section-6 */}
            <div className='Home-6'>
                <div className='wrapp'>
                    <div className='cards'>
                        <img src={project} alt='' />
                        <strong>55 +</strong>
                        <p>Projects Delivered</p>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className='cards'>
                        <img src={happy} alt='' />
                        <strong>25 +</strong>
                        <p>Happy Customers</p>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className='cards'>
                        <img src={client} alt='' />
                        <strong>95 %</strong>
                        <p>Client Retention</p>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className='cards'>
                        <img src={coffee} alt='' />
                        <strong>1000 +</strong>
                        <p>Coffee Served</p>
                    </div>
                </div>
            </div>

            {/* Section-7 */}

            <div className='Home-2'>
                <div className='Field'>
                    <strong style={{ fontSize: '30px' }}>Our Process</strong>
                    <p>These 5 D helps us to do what is expected from us. Let’s invest some dedicated time on this.
                        It pays for itself in due time.</p>
                </div>
                <div className='maincompo'>
                    <div className='card'>
                        <div className='card-1'>
                            <img src={discussion} alt='' />
                            <strong>Discuss</strong>
                            <p>we acknowledge and understand different business domain and how it functions.
                                Discuss the things out.</p>
                            <span>01</span>
                        </div>
                        <div className='card-1'>
                            <img src={define} alt='' />
                            <strong>Define</strong>
                            <p>Once team understand, we can identify the core problem and define the solution that
                                fits right for your organisation.</p>
                            <span>02</span>
                        </div>
                        <div className='card-1'>
                            <img src={design} alt='' />
                            <strong>Design</strong>
                            <p>Put the action plan which is being designed by talented team members. They formalise
                                the actual solution and design drafting is initiated.</p>
                                <span>03</span>
                        </div>
                    </div><br />
                    <div className='card'>
                        <div className='card-1'>
                            <img src={development} alt='' />
                            <strong>Develop</strong>
                            <p>As per the visualised designs, development of solution is kick started.</p>
                            <span>04</span>
                        </div>
                        <div className='card-1'>
                            <img src={delivery} alt='' />
                            <strong>Deliver</strong>
                            <p>We are getting into the final leg of deploying the software solution that is passed
                                through quality checks.</p>
                            <span>05</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Home-8'>
                <Slider /></div>
            <div className='Home-9'>
                <div>
                    <section className="contact-third ">
                        <section className="contact-third-left">
                            <h1 className="contact-heading">Lets Connect</h1>
                            <p className="contact-text">
                                We provide all services in the field of software development,
                                process automation, quality control, resource on hire and much
                                more. Just say Hello to open up the Dialogue. We at Reveation will
                                be happy to serve you.
                            </p>
                        </section>
                        <section className="contact-third-right">
                            <ContactForm />
                        </section>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default Home;