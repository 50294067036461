import React, { useState } from 'react';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import MenuIcon from '@mui/icons-material/Menu';
import './header.css';
import revlogo from '../../assets/logo/logo (2).png';

function Header() {
  const [isCustomAppDropdownOpen, setIsCustomAppDropdownOpen] = useState(false);
  const [isHireDeveloperDropdownOpen, setIsHireDeveloperDropdownOpen] = useState(false);

  const handleCustomAppDropdownOpen = () => {
    setIsCustomAppDropdownOpen(true);
  };

  const handleCustomAppDropdownClose = () => {
    setIsCustomAppDropdownOpen(false);
  };

  const handleHireDeveloperDropdownOpen = () => {
    setIsHireDeveloperDropdownOpen(true);
  };

  const handleHireDeveloperDropdownClose = () => {
    setIsHireDeveloperDropdownOpen(false);
  };

  return (
    <div className='nav'>
      <nav>
        <div className='menuicon'>
          <MenuIcon style={{ fontSize: 'xx-large' }} />
        </div>
        <div className="logo">
        <a href="/"><img src={revlogo} alt="Logo" /></a>
        </div>
        <ul className="menu">
          <li>
            <a
              href="#"
              onMouseEnter={handleCustomAppDropdownOpen}
              onMouseLeave={handleCustomAppDropdownClose}
            >
              Products
              <ArrowDropDownRoundedIcon style={{ fontSize: 'xxx-large', marginLeft: '-12px' }} />
            </a>
            {isCustomAppDropdownOpen && (
              <ul className="dropdown-menu" onMouseEnter={handleCustomAppDropdownOpen} onMouseLeave={handleCustomAppDropdownClose}>
                <li><a href="/wellness-center-management-solution">Wellness Center Management Solution</a></li>
                <li><a href="/rental-property-management-solution">Rental Property Management Solution</a></li>
                <li><a href="/market-place-solution">Market Place Solution</a></li>
                <li><a href="/sales-&-distribution-solution">Sales & Distribution Solution</a></li>
                <li><a href="/hr-management-solution">HR Management Solution</a></li>
                <li><a href="/project-management-solution">Project Management Solution</a></li>
              </ul>
            )}
          </li>
          <li>
            <a
              href="/hire-a-developer"
              onMouseEnter={handleHireDeveloperDropdownOpen}
              onMouseLeave={handleHireDeveloperDropdownClose}
            >
              Hire a Developer
              <ArrowDropDownRoundedIcon style={{ fontSize: 'xxx-large', marginLeft: '-12px' }} />
            </a>
            {isHireDeveloperDropdownOpen && (
              <ul className="dropdown-menu" onMouseEnter={handleHireDeveloperDropdownOpen} onMouseLeave={handleHireDeveloperDropdownClose}>
                <li><a href="/hire-reactjs-developer">Hire React JS Developer</a></li>
                <li><a href="/hire-reactnative-developer">Hire React Native Developer</a></li>
                <li><a href="/hire-nodejs-developer">Hire Node JS Developer</a></li>
                <li><a href="/hire-uiux-designer">Hire UI UX Designer</a></li>
                <li><a href="/hire-manual-tester">Hire Manual Tester</a></li>
                <li><a href="/hire-automation-tester">Hire Automation Tester</a></li>
              </ul>
            )}
          </li>
          <li><a href="/our-portfolio">Our Portfolio</a></li>
          <li><a href="/about-us">About Us</a></li>
          <li><a href="/blog">Blog</a></li>
          <li><a href="/contact-us">Contact Us</a></li>
        </ul>
        <ul className="menu-1">
          <li><a>Custom Applications</a></li>
          <li><a>Hire a Developer </a></li>
          <li><a href="#">Our Portfolio</a></li>
          <li><a href="#">About Us</a></li>
          <li><a href="#">Blog</a></li>
          <li><a href="/contactus">Contact Us</a></li>
        </ul>
      </nav>
    </div>
  );
}

export default Header;
