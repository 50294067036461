import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import '../../Products/products.css';
import rental from '../../../assets/image/project-managment-solution.png'
import back from '../../../assets/image/15.png';
import manage from '../../../assets/logo/product-search.png';
import boarding from '../../../assets/logo/wish-list.png';
import consultation from '../../../assets/logo/product-management.png';
import auto from '../../../assets/logo/product-approval.png';
import Inventory from '../../../assets/logo/commission-management.png';
import house from '../../../assets/logo/inventory-managment.png';
import membership from '../../../assets/logo/order-management.png';
import pricing from '../../../assets/logo/compare-product.png';
import choose from '../../../assets/image/choose-us4.png';
import Slider from '../../../components/Slider/Slider';

function Project() {
  return (
    <div className='MainWellness'>
      <div className='section-1'>
        <h5>Project Management Solution</h5>
        <strong>Choose Reveation For One Stop Project Management Solution.</strong>
        <div className='thumb'>
          <img src={rental} alt='' /></div>
      </div>

      <div className='section-2'>
        <div className='heading-2'>
          <strong>Project Management Features</strong>
          <div className='dev'>
            <b className='devider1'></b>
            <b className='devider'></b>
          </div>
          <p>Projects have their particular needs and corresponding enterprise goals. No challenge is the same, and to your challenge to be successfully delivered. Our project management control specializes in elevating consistency, knowledge, visibility, flexibility and professional support. we’ve blended effective net primarily based totally initiatives with CRM, billing and extra into the unmarried enterprise control solution. Finally, one piece of software program to your whole enterprise</p>
        </div>
        <div className='Cards'>
          <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
            <div className='CardsImg'>
              <img src={manage} alt='' />
              <div className='second'></div>
            </div>
            <h4>Project Assignment</h4>
            <p>This software helps to Save effort and time through mechanically changing projects, duplicating present projects, or the usage of task templates. Project is being assigned. Project billing – For regular bills, add the list of products and attach those to customers and invoices. They create subscription products and characterise the one which are routinely billed at intervals set. The automatic reminders are being set and creating custom templates.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
            <div className='CardsImg'>
              <img src={boarding} alt='' />
              <div className='second'></div>
            </div>
            <h4>Project Status</h4>
            <p>Using our workflow software program to control your small commercial enterprise will make getting unorganized a separate mission inside itself. Use capabilities including challenge dependencies to ensure a technique is usually being followed, and maintain everybody updated on what wishes to be accomplished thru reminders, to-dos, and shared calendars.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
            <div className='CardsImg'>
              <img src={consultation} alt='' />
              <div className='second'></div>
            </div>
            <h4>Project Invoice</h4>
            <p>Incoming invoices traces are mechanically matched to their corresponding buy order traces, which reduces guide tasks. Send the bill and the related matched PO and receipt records on your accounting machine or ERP.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
            <div className='CardsImg'>
              <img src={auto} alt='' />
              <div className='second'></div>
            </div>
            <h4>Profits & Efficiency</h4>
            <p>Project profitability describes the ability of the project to yield an economic earnings or benefit for an organization. As organisation has to grow quickly, some organisations may take the projects with slim profit margins which can impact the financial health of project.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={Inventory} alt='' />
              <div className='second'></div>
            </div>
            <h4>Project Summary</h4>
            <p>Project summary is management tool which summarises the project status. They give details of project milestone, project timeline updated to keep project teams on track, help them to get organised and work together efficiently.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={house} alt='' />
              <div className='second'></div>
            </div>
            <h4>Outstanding Payment</h4>
            <p>Gets clear view of vendor inventory for specific products. Rules are being set up to display products as ‘in stock, out of stock for specific vendors.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={membership} alt='' />
              <div className='second'></div>
            </div>
            <h4>Project Snapshot</h4>
            <p>This is basic level in project management which provides information about planning, execution, monitoring of project. This gives overview of data associated with project.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={pricing} alt='' />
              <div className='second'></div>
            </div>
            <h4>Vertical Revenue</h4>
            <p>This method gives the information to investors to assess the revenue at detailed level over several time period of project. The vertical method allows for easier comparison which helps to plan future revenue expectations.</p>
          </div>
        </div>
      </div>

      <div className='section-3'>
        <div className='container-3'>
          <div className='left'>
            <strong>We provide free demo
              <br />For services being offered</strong>
            <p>Fill the form so that we can help you to serve you better.</p>
          </div>
          <div className='right'>
            <div className='button'>Free Demo</div>
          </div>
        </div>
      </div>

      <div className='section-4'>
        <div className='left'>
          <h5>WHY CHOOSE US</h5>
          <h2>Choose us for best project planning</h2>
          <ul style={{ listStyle: 'none' }}>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Attention to Details</h5></li>
            <p>This software gives details about the small things, scheduling for timelines and keen management which make us stand out from the rest. This help to keep close eye on budget.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Comprehensive Project Management</h5></li>
            <p>This is document which is used for execution. This will help to track the performance of project and assess whether corrective action is required. This helps to communicate key information to stakeholders regarding the project.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>One Stop Solution</h5></li>
            <p>This one stop solution for all project management related worries. This makes assured that the project is completed on time and with the budget decided beforehand.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>24*7 Support</h5></li>
            <p>This is a support mechanism to get all queries to get solved. We want the client to be satisfied with the services. We will do it to make clients happy. No hassles and no problems.</p>
          </ul>
        </div>
        <div className='right'>
            <img src={choose} alt=''/>
        </div>
      </div>

      {/* <div className='section-6'>
        <Slider/>
      </div> */}
    </div>
  )
}

export default Project;