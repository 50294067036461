import React from 'react'
import Header from '../common/header/header';
import Footer from '../common/footer/footer';
import './index.css'
import Home from '../pages/Home/Home';
import Contact from '../pages/ContactUs/contactus';
import About from '../pages/AboutUs/About';
import UIUX from '../pages/HireDev/components/UIUX';
import HIreDev from '../pages/HireDev/HIreDev';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ReactJS from '../pages/HireDev/components/ReactJS';
import ReactNative from '../pages/HireDev/components/ReactNative';
import NodeJS from '../pages/HireDev/components/NodeJS';
import Manual from '../pages/HireDev/components/ManualTester';
import AutomationTester from '../pages/HireDev/components/AutomationTester';
import Blog from '../pages/Blogs/Blog';
import Bloginfo from '../pages/Blogs/Blog-info/Bloginfo';
import Wellness from '../pages/Products/Wellness Center Management Solution/Wellness';
import Rental from '../pages/Products/Rental Property Management Solution/Rental';
import Market from '../pages/Products/Market Place Solution/Market';
import Sales from '../pages/Products/Sales & Distribution Solution/Sales';
import HR from '../pages/Products/HR Management Solution/HR';
import Project from '../pages/Products/Project Management Solution/Project';

function Index() {
  return (
  <div className='mainpage'>
    <div className='header'>
    <Header/></div>
    <div className='MainPage'>
      <Router>
        <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route  path="/hire-reactjs-developer" element={<ReactJS/>} />
        <Route  path="/hire-reactnative-developer" element={<ReactNative/>} />
        <Route  path="/hire-nodejs-developer" element={<NodeJS/>} />
        <Route  path="/hire-uiux-designer" element={<UIUX/>} />
        <Route  path="/hire-manual-tester" element={<Manual/>} />
        <Route  path="/hire-automation-tester" element={<AutomationTester/>} />
        <Route  path="/hire-a-developer" element={<HIreDev/>} />
        <Route  path="/about-us" element={<About/>} />
        <Route  path="/contact-us" element={<Contact/>} />
        <Route  path="/blog" element={<Blog/>} />
        <Route  path="/blog-info" element={<Bloginfo/>} />
        <Route  path="/wellness-center-management-solution" element={<Wellness/>}/>
        <Route  path="/rental-property-management-solution" element={<Rental/>}/>
        <Route  path="/market-place-solution" element={<Market/>}/>
        <Route  path="/sales-&-distribution-solution" element={<Sales/>}/>
        <Route  path="/hr-management-solution" element={<HR/>}/>
        <Route  path="/project-management-solution" element={<Project/>}/>


        </Routes>
      </Router>

    </div>
    <Footer/>
    </div>
  )
}

export default Index;