import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import '../../Products/products.css';
import rental from '../../../assets/image/market-place-solution.png'
import back from '../../../assets/image/15.png';
import manage from '../../../assets/logo/product-search.png';
import boarding from '../../../assets/logo/wish-list.png';
import consultation from '../../../assets/logo/product-management.png';
import auto from '../../../assets/logo/product-approval.png';
import Inventory from '../../../assets/logo/commission-management.png';
import house from '../../../assets/logo/inventory-managment.png';
import payroll from '../../../assets/logo/shipping-management.png';
import membership from '../../../assets/logo/order-management.png';
import pricing from '../../../assets/logo/compare-product.png';
import choose from '../../../assets/image/choose-us1.png';
import Slider from '../../../components/Slider/Slider';

function Market() {
  return (
    <div className='MainWellness'>
      <div className='section-1'>
        <h5>Market Place Solution</h5>
        <strong>Advanced Marketplace Software For Every Business And Any Products.</strong>
        <div className='thumb'>
          <img src={rental} alt='' /></div>
      </div>

      <div className='section-2'>
        <div className='heading-2'>
          <strong>Market Place Features</strong>
          <div className='dev'>
            <b className='devider1'></b>
            <b className='devider'></b>
          </div>
          <p>Experience spike in the business income while using our software. This software It is being tailored to be integrated with any third party software’s which your company is planning to use.</p>
        </div>
        <div className='Cards'>
          <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
            <div className='CardsImg'>
              <img src={manage} alt='' />
              <div className='second'></div>
            </div>
            <h4>Product Search</h4>
            <p>User can search the product which one is looking for, by name, brand or category. Filters can be done by price range to help users identify products quickly.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
            <div className='CardsImg'>
              <img src={boarding} alt='' />
              <div className='second'></div>
            </div>
            <h4>Wish List</h4>
            <p>After screening the products, user may add few products in cart other user may park those products aside in Wishlist for future use.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
            <div className='CardsImg'>
              <img src={consultation} alt='' />
              <div className='second'></div>
            </div>
            <h4>Product Management</h4>
            <p>it allows management of products to be added, edited or upgraded by vendors.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
            <div className='CardsImg'>
              <img src={auto} alt='' />
              <div className='second'></div>
            </div>
            <h4>Product Approval</h4>
            <p>Admin gets full right to approve the products uploaded by vendors. The products are being added on web portal post the approval from admin team.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={Inventory} alt='' />
              <div className='second'></div>
            </div>
            <h4>Commission Management</h4>
            <p>Flexible commissions for vendors are being set up by vendors which depends on product categories. We can apply tax rules and commission is applied on each order.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={house} alt='' />
              <div className='second'></div>
            </div>
            <h4>Inventory Management</h4>
            <p>Gets clear view of vendor inventory for specific products. Rules are being set up to display products as ‘in stock, out of stock for specific vendors.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={payroll} alt='' />
              <div className='second'></div>
            </div>
            <h4>Shipping Management</h4>
            <p>Try to set vendor specific shipping charges to promote the best-selling vendors or on products. Choose from multiple shipping partners to optimise shipping costs and maximum ROI.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={membership} alt='' />
              <div className='second'></div>
            </div>
            <h4>Order Management</h4>
            <p>Allow vendors to maintain the order and ship the products without interruption. User can get easy view of orders with complete details with open, cancelled and closed orders. This will allow vendors to generate and print invoice, shipped order and cancel order.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={pricing} alt='' />
              <div className='second'></div>
            </div>
            <h4>Compare Products</h4>
            <p>Once the products are added to the dashboard, then buyer can compare the products based on different vendors. Giving the opportunity to customers to analyse, rate the products.</p>
          </div>
        </div>
      </div>

      <div className='section-3'>
        <div className='container-3'>
          <div className='left'>
            <strong>We provide free demo
              <br />For services being offered</strong>
            <p>Fill the form so that we can help you to serve you better.</p>
          </div>
          <div className='right'>
            <div className='button'>Free Demo</div>
          </div>
        </div>
      </div>

      <div className='section-4'>
        <div className='left'>
          <h5>WHY CHOOSE US</h5>
          <h2>Cutting edge technologies for software solutions</h2>
          <ul style={{ listStyle: 'none' }}>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Configured to your needs</h5></li>
            <p>The software is built as per the changing needs of the client. It is customised as per changing market need.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Real Time</h5></li>
            <p>Ecommerce platform default function which allow vendors to use world famous shipping methods which is being used for real time shipping calculations.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Omni Channel</h5></li>
            <p>We provide consistent services for multiple sales channels, online marketplaces and company websites.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Need integration</h5></li>
            <p>Provide customised integration as our middlemen is at service. We use our e-commerce channel integration functionality.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Mobile Friendly</h5></li>
            <p>As mobile are accessible to all, we create Mobile friendly software for our clients.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Offline</h5></li>
            <p>They fulfil online website and marketplace orders from the offline store network.</p>
          </ul>
        </div>
        <div className='right'>
            <img src={choose} alt=''/>
        </div>
      </div>

      {/* <div className='section-6'>
        <Slider/>
      </div> */}
    </div>
  )
}

export default Market;