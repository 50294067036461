import React, { useState, useEffect } from 'react';
import './Slider.css';
import map from '../../assets/image/map.svg';
import { useMediaQuery } from 'react-responsive';

function Slider() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleSlideChange = (index) => {
    setCurrentSlide(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const nextSlide = (currentSlide + 1) % 5;
      setCurrentSlide(nextSlide);
    }, 2500);

    return () => clearInterval(interval);
  }, [currentSlide]);

  return (
    <div className='Home-slider'>
      <strong>Customers Review</strong>
      <div
        className='Home-slider-1'
        style={isMobile ? {} : { backgroundImage: `url(${map})`}}
      >
            <div className='Field'>
                <div className='dev'>
                    <b className='devider1'></b>
                    <b className='devider'></b>
                </div>
            </div>
            <div className='Slider'>
                <div className='Slides'>
                    <div
                        className={`Slide ${currentSlide === 0 ? 'active' : ''}`}
                    >
                        <p>
                            we were looking for in our work process. Highly recommended for use if you are looking for a custom solution.
                        </p>
                        <strong>BRIAN SMITH</strong>
                    </div>
                    <div
                        className={`Slide ${currentSlide === 1 ? 'active' : ''}`}
                    >
                        <p>
                            Thank you for the perfect solution you created for us. The total time it has saved me has been phenomenal. Our productivity has increased by 30% thanks to the automations. Team Reveation Rocks!!
                        </p>
                        <strong>SUSHIL MITTAL</strong>
                    </div>
                    <div
                        className={`Slide ${currentSlide === 2 ? 'active' : ''}`}
                    >
                        <p>
                        The property management software you gave us has helped us nullify the manual processes and the integrations have helped us reduce on having to use multiple systems. Working experience with Reveation was absolutely at par.
                        </p>
                        <strong>VIKRAM PATEL</strong>
                    </div>
                    <div
                        className={`Slide ${currentSlide === 3 ? 'active' : ''}`}
                    >
                        <p>
                        Thanks a ton guys for the wonderful mobile app developed for us. It has done exactly what we expected it would help us achieve.
                        </p>
                        <strong>KRIS PARKER</strong>
                    </div>
                    <div
                        className={`Slide ${currentSlide === 4 ? 'active' : ''}`}
                    >
                        <p>
                        In one word - committed. Though we had quite a few debates and discussions we are happy to say your team's commitment to the project is appreciated. We will work together soon.
                        </p>
                        <strong>LISA SMITH</strong>
                    </div>
                </div>
                <div className='Bullets'>
                    {Array.from({ length: 5 }).map((_, index) => (
                        <span
                            key={index}
                            className={`Bullet ${currentSlide === index ? 'active' : ''}`}
                            onClick={() => handleSlideChange(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div></div>
    );
}

export default Slider;
