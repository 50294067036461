import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import '../../Products/products.css';
import rental from '../../../assets/image/sales-distribution-solution.png'
import back from '../../../assets/image/15.png';
import manage from '../../../assets/logo/product-search.png';
import boarding from '../../../assets/logo/wish-list.png';
import consultation from '../../../assets/logo/product-management.png';
import auto from '../../../assets/logo/product-approval.png';
import Inventory from '../../../assets/logo/commission-management.png';
import house from '../../../assets/logo/inventory-managment.png';
import payroll from '../../../assets/logo/shipping-management.png';
import membership from '../../../assets/logo/order-management.png';
import pricing from '../../../assets/logo/compare-product.png';
import choose from '../../../assets/image/choose-us3.png';
import Slider from '../../../components/Slider/Slider';

function HR() {
  return (
    <div className='MainWellness'>
      <div className='section-1'>
        <h5>HR Management Solution</h5>
        <strong>Best HRMS Software For Customised On Boarding</strong>
        <div className='thumb'>
          <img src={rental} alt='' /></div>
      </div>

      <div className='section-2'>
        <div className='heading-2'>
          <strong>HR Management Features</strong>
          <div className='dev'>
            <b className='devider1'></b>
            <b className='devider'></b>
          </div>
          <p>The purpose of software is to automate the HR workflow by allowing HR analysis which led to business decision making. This software can help to retrieve the employee information. This enables the business to plan the expenses better. This saves time and effort of HR, benefit managers and employees. HR suites are configured and customised so to meet client’s needs. They have different functionalities where one can choose the features one wants in the package.</p>
        </div>
        <div className='Cards'>
          <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
            <div className='CardsImg'>
              <img src={manage} alt='' />
              <div className='second'></div>
            </div>
            <h4>Leave Management</h4>
            <p>The employee can apply for leaves in system. Create different type of leaves and maintain them. Leave credit rule is added. Supervisor /HR can view subordinate leave details. Supervisor/or HR will calculate leave balance calculation. System will calculate the leave carry forward. Leave encashment is calculated.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
            <div className='CardsImg'>
              <img src={boarding} alt='' />
              <div className='second'></div>
            </div>
            <h4>Employee Employment</h4>
            <p>Generating employment agreement and appointment letter is also added. Probation period and confirmation status is updated. Name of department where employee is joining, details like role and designation is updated. UAN and PF account details are upgraded in system.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
            <div className='CardsImg'>
              <img src={consultation} alt='' />
              <div className='second'></div>
            </div>
            <h4>On Boarding</h4>
            <p>Adding new employee in database – as doctor, therapist, admin, housekeeping etc. personal information of employee – name of employee, date of birth, blood group and passport size photo. Adding details like present and permanent address and Bank details, Family details are being added. Identification proof – Pan-card, address proof is upgraded.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
            <div className='CardsImg'>
              <img src={auto} alt='' />
              <div className='second'></div>
            </div>
            <h4>Attendance Management</h4>
            <p>The biometrics where in time and out-time of employee is updated. Record movements via access points are updated in system.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={Inventory} alt='' />
              <div className='second'></div>
            </div>
            <h4>Hiring</h4>
            <p>The candidate is hired for one position. Candidate details like such as name, skills, relevant experience and highest education are being stored in system.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={house} alt='' />
              <div className='second'></div>
            </div>
            <h4>Reimbursement</h4>
            <p>Reimbursement of expenses are made once bills are submitted. Disbursement of additional Benefits like allowances are calculate and updated in system. Dual approval is being updated in system.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={payroll} alt='' />
              <div className='second'></div>
            </div>
            <h4>Payroll Management</h4>
            <p>Employee’s salary is calculated based on Attendance Documents - Tax-related Declarations, Rent details, 80 C investments, home loans. Additional deduction if any will be deducted. Leave encashment settlement to made. Full and final payment to be processed for employees who resigned.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={membership} alt='' />
              <div className='second'></div>
            </div>
            <h4>Appraisal</h4>
            <p>Allow vendors to maintain the order and ship the products without interruption. User can get easy view of orders with complete details with open, cancelled and closed orders. This will allow vendors to generate and print invoice, shipped order and cancel order.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={pricing} alt='' />
              <div className='second'></div>
            </div>
            <h4>Separation Management</h4>
            <p>Once the candidate has given resignation, the resignation has been captured in system. The employee clearance has been done from various departments. Generating relieving and exit letters. Uploading and maintaining easy uploads of exit letters.</p>
          </div>
        </div>
      </div>

      <div className='section-3'>
        <div className='container-3'>
          <div className='left'>
            <strong>We provide free demo
              <br />For services being offered</strong>
            <p>Fill the form so that we can help you to serve you better.</p>
          </div>
          <div className='right'>
            <div className='button'>Free Demo</div>
          </div>
        </div>
      </div>

      <div className='section-4'>
        <div className='left'>
          <h5>WHY CHOOSE US</h5>
          <h2>Choose us for smart and affordable software</h2>
          <ul style={{ listStyle: 'none' }}>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Effective communication</h5></li>
            <p>The basic function of HRMS is about people. Coach them and supporting to solve problems and ultimately widening business performance. This software helps to break the monotony from tedious and administration-based work load so that they can focus on people-oriented tasks which makes a difference.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Reduce Costs</h5></li>
            <p>HR team members take responsibility of carrying out multiple tasks on continuous basis. This involved recruiting to on boarding the new employees to managing the performance appraisal for the business. They can automate workflow, update changes and capture critical business data.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Timely Data Analysis</h5></li>
            <p>HRMS software helps to track employee real time data, absence data and other HR key metrics. The business will benefit from these data access and ability to support business decisions and formulate future business strategies.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Store HRIS Documents</h5></li>
            <p>Configure employee on boarding workflow in HRIS system to collect these documents document with new employees. This can be accessed through self-service portal by employee and stakeholder.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Goodbye to Paper Form</h5></li>
            <p>Bid goodbye to all the forms and automate the hiring outflow.</p>
          </ul>
        </div>
        <div className='right'>
            <img src={choose} alt=''/>
        </div>
      </div>

      {/* <div className='section-6'>
        <Slider/>
      </div> */}
    </div>
  )
}

export default HR;