import React from 'react';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import HomeIcon from '@mui/icons-material/Home';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import CallRoundedIcon from '@mui/icons-material/CallRounded';
import Divider from '@mui/material/Divider';
import './footer.css'
import revlogo from '../../assets/logo/logo1.png';

function Footer() {
    return (
        <div className='footermain'>
            <div className='footermain-1'>
                <div className='reveation'>
                <a href="/"><img src={revlogo} alt='' /></a>
                    <p>Provider of marked excellence for different types of industries through custom software development as well as ready to be used software modules as a service. We keep moving forward with you in your journey of attaining excellence.</p>
                </div>
                <div className='customapplication'>
                    <p>Custom Applications</p>
                    <ul>
                        <li><a href="/wellness-center-management-solution"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Wellness Center Management Solution</span></a></li>
                        <li><a href="/rental-property-management-solution"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Rental Property Management Solution</span></a></li>
                        <li><a href="/market-place-solution"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Market Place Solution</span></a></li>
                        <li><a href="/sales-&-distribution-solution"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Sales & Distribution Solution</span></a></li>
                        <li><a href="/hr-management-solution"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>HR Management Solution</span></a></li>
                        <li><a href="/project-management-solution"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Project Managememnt Solution</span></a></li>
                    </ul>
                </div>
                <div className='customapplication'>
                    <p>Hire a Developer</p>
                    <ul>
                        <li><a href="/hire-reactjs-developer"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Hire React JS Developer</span></a></li>
                        <li><a href="/hire-reactnative-developer"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Hire React Native Developer</span></a></li>
                        <li><a href="/hire-nodejs-developer"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Hire Node JS Developer</span></a></li>
                        <li><a href="/hire-uiux-designer"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Hire UI UX Designer</span></a></li>
                        <li><a href="/hire-manual-tester"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Hire Manual Tester</span></a></li>
                        <li><a href="/hire-automation-tester"><KeyboardArrowRightOutlinedIcon style={{ fontSize: '20px' }} /><span>Hire Automation Tester</span></a></li>
                    </ul>
                </div>
                <div className='contactinfo'>
                    <strong>Contact Info</strong>
                    <div className='address-1'>
                        <div className='Address'>
                            <div>
                                <HomeIcon className='logo' />
                            </div>
                            <div className='addrewrite'>
                                    <strong>Address:</strong>
                                    <p>A- 516, Ratnaakar 9 Square,
                                        Opp ITC Narmada,
                                        Keshavbag, Vastrapur,
                                        Ahmedabad- 380015.</p>
                            </div>
                        </div>
                        <div className='Address'>
                            <div>
                                <EmailRoundedIcon className='logo' />
                            </div>
                            <div className='addrewrite'>
                                    <strong>Email:</strong><br/>
                                    <a href='mailto:info@reveation.com'>info@reveation.com</a>
                            </div>
                        </div>
                        <div className='Address'>
                            <div>
                                <CallRoundedIcon className='logo' />
                            </div>
                            <div className='addrewrite'>
                                    <strong>Phone:</strong><br/>
                                    <a href='tel:09989773391'>+91 9989773391</a>
                            </div>
                        </div>
                        </div>
                </div>
            </div>
            <Divider sx={{ backgroundColor: '#25285f' }}/>
            <div className='footermain-2'>
                <p>© Copyright 2022.
                    <strong>Reveation | Offshore Software Development Company in India</strong>
                </p>
                <div className='Last'>
                    <b>Terms & Conditions </b>
                     |
                    <b> Privacy Policy </b>
                </div>
            </div>
        </div>
    )
}

export default Footer;