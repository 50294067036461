import React from 'react';
import About1 from "../../assets/image/UiUx.svg";
import css from "./HireDev.module.scss";
import ReactNative from './components/ReactNative';
import NodeJS from './components/NodeJS';
import UIUX from './components/UIUX';
import Manual from './components/ManualTester';
import AutomationTester from './components/AutomationTester';
import image from "../../assets/image/how-to-hire.png"
import l1 from "../../assets/image/1.jpg"
import l2 from "../../assets/image/Screenshot 2023-06-01 155410.jpg"
import l3 from "../../assets/image/3.jpg"
import reactdev from "../../../src/assets/image/react.png"
import node from "../../../src/assets/image/Nodejs.svg"
import manual from "../../assets/image/manual-tester.png"
import reactn from "../../assets/image/react-js-native-developer.png"
import uiux from "../../assets/image/UiUx.svg"
import auto from "../../assets/image/automation-tester.png"
import { Helmet } from 'react-helmet';


const HIreDev = () => {
  return (
    <>

<Helmet>
        <title>Hire Software Developers | Reveation</title>
        <meta name="description" content="Looking to hire software developers for short-term or long-term engagements? Reveation offers various engagement models and a pool of talented professionals to cater to your requirements." />
      </Helmet>


<div className={css.container}>
    <div className={css.section1}>
        <div className={css.heading}>Want to hire <br/>
<b>Software Developers</b> <br/>
for short term or long-term Engagement?
</div>
        <div className={css.text}>
Are you in need of any kind of developer? We have various engagement models to fit in your requirements. You can hire Developers on Hourly basis or weekly engagementor Monthly engagements, for short term or long term, we are happy to cater your requirements by offering right talent for your project.

</div>
<button className={css.button}>Get Started</button>
    </div>
    <div className={css.section2}>
        <img src={About1} alt="hire developer" title='hire developer' className={css.img1} />
    </div>


    

</div>



<div className={css.container2}>
    <div className={css.section3}>
        <div className={css.heading}>Hire A Developer

</div>
<div className={css.divider}></div>

        <div className={css.text}>
        Searching for offshore development team in India for your projects ?We have been providing software developers and web developers and mobile app developers for iOS or android on hire No hassle of hiring, you can use resources as much as you need and relieve them.


</div>
    </div>
   


    

</div>

<div className={css.container3}>
    <section className={css.sec1}>
    <div className={css.container}>
            <div className={css.section11}>
                <div className={css.title}>HIRE REACT JS DEVELOPER
</div>
                <div className={css.heading}>Choose an expert, intermediate or a novice as your project demands.
</div>
                <div className={css.text}>At Reveation, we have good pool of talented and skilled professionals where companies can choose from. These skilled professionals come with rich years of Experience in handling requirement of clients pertaining to web or mobile application development.

</div>
            </div>
            <div className={css.section2}>
            <img src={reactdev} alt="ReactJS developer" title="ReactJS developer" className={css.img} />
            </div>
        </div>
    </section>
   
</div>

<div className={css.container12}>
    <section className={css.sec1}>
    <div className={css.container}>

    <div className={css.section1}>
    <img src={reactn} alt="React Native developer" title="React Native developer" className={css.img}  />
            </div>
            <div className={css.section2}>
                <div className={css.title}>HIRE REACT NATIVE DEVELOPER

</div>
                <div className={css.heading}>Looking to have a React Native specialist on your team?

</div>
                <div className={css.text}>Wanted to have interactive interface creation for any platform. Looking for professionals who are having experience in binding and working with different frontend development knowledge.
<br/>
Large skillsets with hands-on experience are available for you to pick from.


</div>
            </div>
           
        </div>
    </section>
 </div>  









<div className={css.container3}>
    <section className={css.sec1}>
    <div className={css.container}>
            <div className={css.section11}>
                <div className={css.title}>HIRE NODE JS DEVELOPER
</div>
                <div className={css.heading}>Choose an expert, intermediate or a novice as your project demands.
</div>
                <div className={css.text}>At Reveation, we have good pool of talented and skilled professionals where companies can choose from. These skilled professionals come with rich years of Experience in handling requirement of clients pertaining to web or mobile application development.

</div>
            </div>
            <div className={css.section2}>
            <img src={node} alt="NodeJS developer" title="NodeJS developer" className={css.img} />
            </div>
        </div>
    </section>
   
</div>


<div className={css.container12}>
    <section className={css.sec1}>
    <div className={css.container}>

    <div className={css.section1}>
    <img src={uiux} alt="UIUX developer" title="UIUX developer" className={css.img} />
            </div>
            <div className={css.section2}>
                <div className={css.title}>HIRE UI UX DESIGNER

</div>
                <div className={css.heading}>Looking to have a UI UX specialist on your team?

</div>
                <div className={css.text}>At Reveation, we have good pool of talented and skilled professionals where companies can choose from. These skilled professionals come with rich years of Experience in handling requirement of clients pertaining to web or mobile application development.


</div>
            </div>
           
        </div>
    </section>
 </div>  




<div className={css.container3}>
    <section className={css.sec1}>
    <div className={css.container}>
            <div className={css.section11}>
                <div className={css.title}>HIRE MANUAL TESTER

</div>
                <div className={css.heading}>Find all bugs, faults and flaws in your project or product.

</div>
                <div className={css.text}>Picking the experts which can help to run the process through thick and thin. They make sure the project is well tested with various functional aspects and is up to mark as per clients’ requirements.



</div>
            </div>
            <div className={css.section2}>
            <img src={manual} alt="Manual Tester"  title="Manual Tester" className={css.img} />
            </div>
        </div>
    </section>
   
</div>





<div className={css.container12}>
    <section className={css.sec1}>
    <div className={css.container}>

    <div className={css.section1}>
    <img src={auto}  alt="Automation-tester" title="Automation-tester" className={css.img} />
            </div>
            <div className={css.section2}>
                <div className={css.title}>HIRE AUTOMATION TESTER

</div>
                <div className={css.heading}>Looking for an experienced full stack QA.


</div>
                <div className={css.text}>Our QA experts will help to do wonders for the project, results and their timeline. QA engineers bring the right qualities. There is vast project exposure in automation testing, using selenium TestNG with or without cucumber, using JMeter for performance testing, using Burpsuite for security testing, using post man for API testing and many more.




</div>
            </div>
           
        </div>
    </section>
 </div>  


















<div className={css.container4}>
    <div className={css.section1}>
        <img src={image} className={css.img}/>
    </div>
    <div className={css.section2}>
    <div className={css.heading}>How to Hire?</div>
    <center>
   <section className={css.card}>
    <img src={l1} alt="resource requirements" title='resource requirements' className={css.image} />
    <h5 className={css.h5}>Share your resource requirements
</h5>
    <section className={css.text}>Let’s talk and list down the exact talent requirement which is to be hired for certain project.


</section>
   </section>

   <section className={css.card}>
    <img src={l2} alt="Meet the experts" title='Meet the experts
' className={css.image} />
    <h5 className={css.h5}>Meet the experts

</h5>
    <section className={css.text}>Let’s talk and list down the exact talent requirement which is to be hired for certain project.


</section>
   </section>

   <section className={css.card}>
    <img src={l3} alt="Decide Engagement model & Hire" title='Decide Engagement model & Hire
' className={css.image} />
    <h5 className={css.h5}>Decide Engagement model & Hire
    
</h5>
    <section className={css.text}>Let’s talk and list down the exact talent requirement which is to be hired for certain project.


</section>
   </section>
   </center>


    </div>
</div>

</>
  )
}

export default HIreDev