import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import '../../Products/products.css';
import rental from '../../../assets/image/rental-property-management-solution.png'
import back from '../../../assets/image/15.png';
import manage from '../../../assets/logo/property-management.png';
import boarding from '../../../assets/logo/tenant-master.png';
import consultation from '../../../assets/logo/household.png';
import auto from '../../../assets/logo/rent-management.png';
import Inventory from '../../../assets/logo/user-master.png';
import choose from '../../../assets/image/choose-us1.png';
import Slider from '../../../components/Slider/Slider';

function Rental() {
  return (
    <div className='MainWellness'>
      <div className='section-1'>
        <h5>Rental Property Management Solution</h5>
        <strong>Rent And Manage Your Property By Customised Rental Property Management Software.</strong>
        <div className='thumb'>
          <img src={rental} style={{width:'50%'}} alt='' /></div>
      </div>

      <div className='section-2'>
        <div className='heading-2'>
          <strong>Rental Property Management Features</strong>
          <div className='dev'>
            <b className='devider1'></b>
            <b className='devider'></b>
          </div>
          <p>This software It is being tailored made as per the need of any rental property management software. It encompasses various modules like Tenant Registration, Tenant Billing and complain or service request lodging with exclusive tenant view, Service Request management and tracking, Vendor Registration and management that makes task easier for any property manager and owners.</p>
        </div>
        <div className='Cards'>
          <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
            <div className='CardsImg'>
              <img src={manage} alt='' />
              <div className='second'></div>
            </div>
            <h4>Manage Property</h4>
            <p>Various type and size of properties can be added, modified or deleted with different amenities. Create field name, title, description, image, amenities in key feature list. Details like location details of property, nearby area details, google map navigation, landmark details under head' property master.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
            <div className='CardsImg'>
              <img src={boarding} alt='' />
              <div className='second'></div>
            </div>
            <h4>Manage Tenant Registration</h4>
            <p>Tenant personal details is captured along with current residences, landlord details, prior residence. Move in/move out details added in system. If the tenant is having pet then details of pet- type, breed and name etc. Vehicles owned by tenants- type, model, year, make, colour, license plate number etc. Legal questions to be added for background check.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
            <div className='CardsImg'>
              <img src={consultation} alt='' />
              <div className='second'></div>
            </div>
            <h4>Service Request Maintenance</h4>
            <p>Housekeeping and maintenance services etc. comes under Service request management module managed by property manager. Various Service request has been listed tracked and followed up up to the closure for better tracking.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
            <div className='CardsImg'>
              <img src={auto} alt='' />
              <div className='second'></div>
            </div>
            <h4>Rent Collection Management</h4>
            <p>Property Manager / Owner can keep a track of Rent collection and pending rent items easily by one click. MIS and various collection reports gives a fair idea of profit and expenses. Auto Reminders can be sent to tenants who has pending rent to be paid.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={Inventory} alt='' />
              <div className='second'></div>
            </div>
            <h4>Role Management</h4>
            <p>Various types of roles can be added and managed with different roles and rights for -admin, owner, property manager and tenant.</p>
          </div>
        </div>
      </div>

      <div className='section-3'>
        <div className='container-3'>
          <div className='left'>
            <strong>We provide free demo
              <br />For services being offered</strong>
            <p>Fill the form so that we can help you to serve you better.</p>
          </div>
          <div className='right'>
            <div className='button'>Free Demo</div>
          </div>
        </div>
      </div>

      <div className='section-4'>
        <div className='left'>
          <h5>WHY CHOOSE US</h5>
          <h2>Cutting edge technologies for software solutions</h2>
          <ul style={{ listStyle: 'none' }}>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Third Party Integration</h5></li>
            <p>Solution can be integrated with various third party application for water and electricity billing or accounting or any other purpose.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>E-Rent Documentation</h5></li>
            <p>Rent agreement can be generated, signed and saved digitally and can be maintained easily or fetched anytime needed.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Customer Satisfaction</h5></li>
            <p>To get the proper repairing and maintenance done for properties, get services on demand through property managers tracked by system thus enable to have a happy tenants living in your property.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Ease of MIS</h5></li>
            <p>Get quick information for profitability of the business and can identify easily improvement or corrective actions required by reviewing those stats.</p>

          </ul>
        </div>
        <div className='right'>
            <img src={choose} alt=''/>
        </div>
      </div>

      {/* <div className='section-6'>
        <Slider/>
      </div> */}
    </div>
  )
}

export default Rental;