import React from 'react'
import css from "./Hire.module.scss"
import reactnet from "../../../assets/image/react-js-native-developer.png"
import { Helmet } from 'react-helmet';



const ReactNative = () => {
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Hire React Native Developer - Expertise in Cross-platform App Development | YourCompany</title>
        <meta name="description" content="Looking to hire a React Native developer for interactive interface creation on any platform? Choose from our pool of professionals experienced in frontend development and cross-platform app development. Contact us now!" />
      </Helmet>        <div>
            <div className={css.container}>
                <div className={css.section1}>
                    <div className={css.title}>HIRE REACT NATIVE DEVELOPER</div>

                    <div className={css.heading}>Looking to have a React Native specialist on your team?

    </div>
                    <div className={css.text}>Wanted to have interactive interface creation for any platform. Looking for professionals who are having experience in binding and working with different frontend development knowledge.
<br/>
<br/>
Large skillsets with hands-on experience are available for you to pick from.

    
    </div>
                </div>
                <div className={css.section2}>
    <img src={reactnet} alt="React Native developer" title="React Native developer" className={css.img} style={{width:"80%"}} />
                </div>
            </div>
        </div>
        </>

      )
}

export default ReactNative