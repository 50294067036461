import React from 'react'
import css from "./Hire.module.scss"
import auto from "../../../assets/image/automation-tester.png"
import { Helmet } from 'react-helmet';

const AutomationTester = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Hire Automation Tester - Full Stack QA Experts | YourCompany</title>
        <meta name="description" content="Looking to hire an experienced full stack QA expert for automation testing? Our QA engineers have vast project exposure using selenium TestNG, JMeter, Burpsuite, and more. Contact us now!" />
      </Helmet>
    
    <div>
      
        <div className={css.container}>
            <div className={css.section1}>
                <div className={css.title}>HIRE AUTOMATION TESTER
    
</div>
                <div className={css.heading}>Looking for an experienced full stack QA.

</div>
                <div className={css.text}>Our QA experts will help to do wonders for the project, results and their timeline. QA engineers bring the right qualities. There is vast project exposure in automation testing, using selenium TestNG with or without cucumber, using JMeter for performance testing, using Burpsuite for security testing, using post man for API testing and many more.



</div>
            </div>
            <div className={css.section2}>
<img src={auto}  alt="Automation-tester" title="Automation-tester" className={css.img} />
            </div>
        </div>
    </div></>
  )
}

export default AutomationTester