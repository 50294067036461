import React from "react";
import css from "./About.module.scss";
import About1 from "../../assets/image/about-us2.jpg"
import About2 from "../../assets/image/about-us-graph1.png"
import { MdEmail } from "react-icons/md";
import { ImMap } from "react-icons/im";
import { BsFillTelephoneFill } from "react-icons/bs";
import { Helmet } from "react-helmet";
import icoo from "../../assets/logo/Flaticon.svg"





const About = () => {
  return (
    <>
       <Helmet>
        <title>About Reveation - Software Services Provider</title>
        <meta
          name="description"
          content="Reveation is a software services provider based out of Ahmedabad, Gujarat, India. We build software solutions to add value to businesses. Contact us for your software needs."
        />
      </Helmet>
<div className={css.container}>
    <div className={css.section1}>
        <div className={css.heading}>About Reveation</div>
        <div className={css.text}>Reveation is a software services provider based out of Ahmedabad, Gujarat, India. Team with “Can Do “attitude who believes in smart way of working and quality deliveries. Business processes and routine activities made easy by digitalization and process automation. Software Solutions whether Web applications or Mobile applications or combination of both, we build it to add value to businesses.
<br/>
<br/>
We have clients across globe specifically in US, Europe, Australia, Singapore and India. Our services help businesses magnify scope of their custom software development using latest technologies like React, Angular, React Native. We are acquainted with quality review methods using tools like Selenium, Appium, JMeter functional testing.
</div>
    </div>
    <div className={css.section2}>
        <img src={About1}  className={css.img1} alt="about-us" title="About Reveation"/>
    </div>

</div>

<div className={css.container2}>
    <div className={css.section3}>
<img src={About2} alt="about-us" className={css.img2} title="About Reveation"/>
    </div>
    <div className={css.section4}>
        <div className={css.heading2}>One stop solution for your software needs.</div>
        <div className={css.text2}>Reveation technologies is the one stop software solutions provider in Ahmedabad, India. We have build up the core team which is successful. We have equal share when it comes to software product which is built up and developed to solve client business issues. We cater to the need of client which demands for automation of existing and as well new business process flows.
<br/>
<br/>
We study client’s mission, business objectives, products and problem areas. We have simple solution for the software project We provide creative solutions to clients around the world, creating things that get attention and meaningful.
<br/>
<br/>
We have proved ourselves for building capabilities which gives the boost to business and cost-effective growth. We provide the right business software development solutions. Whether one is business entity build on large portfolio and looking into same league. The team helps to get client on track.
<br/>
<br/>
Our clients spread is across the globe and we successfully showcased end to end business transformation for each of clients.



</div>

<button className={css.button}> Contact Us</button>
    </div>
</div>


<div className={css.container3}>
    <div className={css.section5}>
    <div className={css.heading3}>What Makes Reveation Different?
</div>
    <div className={css.divider}></div>
    </div>
    <div className={css.text3}>We strive hard to satisfy their clients by business goals by aiming on shared alliance. We employ the use of state of art technologies to get the best fit for the needs. We build software mileage which have user experience. We provide the timeless software solutions and make the business by giving timely needed support.</div>
    </div>
    <div className={css.section6}>
    <div className="contact-cards-div">
      <section>
        <div class="testimotionals">
          <div class="card">
            <div class="layer"></div>
            <div class="content">
              <div class="details">
                <span>
                  <MdEmail className="card-icon" />
                  
                </span>
                <h2>
                  {" "}
                  Our Mission <br />{" "}
                  <span>
                    <a>To make life easy</a>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="testimotionals">
          <div class="card">
            <div class="layer"></div>
            <div class="content">
              <div class="details">
                <span className="card-icon">
                  <BsFillTelephoneFill />
                </span>

                <h2>
                  {" "}
                  Our Vision <br />{" "}
                  <span>
                    <a>To create a team that holds,hands of customer in need or extended team in need</a>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="testimotionals">
          <div class="card">
            <div class="layer"></div>
            <div class="content">
              <div class="details">
                <span>
                  <ImMap className="card-icon" />
                </span>

                <h2>
                  {" "}
                  Our strategy <br />{" "}
                  <span>
                    <a>
                     To be Part of solution and not a problem
                    </a>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </div>
    
    </>
  );
};

export default About;