import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import '../../Products/products.css';
import rental from '../../../assets/image/sales-distribution-solution.png'
import back from '../../../assets/image/15.png';
import manage from '../../../assets/logo/product-search.png';
import boarding from '../../../assets/logo/wish-list.png';
import consultation from '../../../assets/logo/product-management.png';
import auto from '../../../assets/logo/product-approval.png';
import Inventory from '../../../assets/logo/commission-management.png';
import house from '../../../assets/logo/inventory-managment.png';
import payroll from '../../../assets/logo/shipping-management.png';
import membership from '../../../assets/logo/order-management.png';
import pricing from '../../../assets/logo/compare-product.png';
import choose from '../../../assets/image/choose-us2.png';
import Slider from '../../../components/Slider/Slider';

function Sales() {
  return (
    <div className='MainWellness'>
      <div className='section-1'>
        <h5>Sales & Distribution Solution</h5>
        <strong>Drive Sales And Grow Your Business</strong>
        <div className='thumb'>
          <img src={rental} alt='' /></div>
      </div>

      <div className='section-2'>
        <div className='heading-2'>
          <strong>Sales & Distribution Features</strong>
          <div className='dev'>
            <b className='devider1'></b>
            <b className='devider'></b>
          </div>
          <p>We are enterprise web and mobility solution which maintains and follow the primary and secondary sales. It is an efficient tool to track and manage sales force Automation from lead generation to order processing, follow-ups, attendance and location tracking.</p>
        </div>
        <div className='Cards'>
          <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
            <div className='CardsImg'>
              <img src={manage} alt='' />
              <div className='second'></div>
            </div>
            <h4>Fully Customizable</h4>
            <p>Passage weather as up am exposed. And natural related man subject. Eagerness get situation his was delighted.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
            <div className='CardsImg'>
              <img src={boarding} alt='' />
              <div className='second'></div>
            </div>
            <h4>Order Management</h4>
            <p>It contains seller order management dashboard with current and completed orders. This gives automated splitting of order with products from several sellers. Automated order invoicing after payment is received.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
            <div className='CardsImg'>
              <img src={consultation} alt='' />
              <div className='second'></div>
            </div>
            <h4>Stock Checking</h4>
            <p>Once the order is placed, software checks Stock of goods.. Order is accepted or rejected on the inventory of items. The software checks the schemes, deals or coupons.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
            <div className='CardsImg'>
              <img src={auto} alt='' />
              <div className='second'></div>
            </div>
            <h4>GPS Tracking</h4>
            <p>The software easily tracks the employee location using the Google Maps. It sends the message to owner for less or no reporting from employees. This ensures sales representative attendance timely market coverage to adherence plan.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={Inventory} alt='' />
              <div className='second'></div>
            </div>
            <h4>Mobile Tracking</h4>
            <p>Tracks the employee tour plan. It will track the employee start day, dashboard. Viewing your plan. Route will be decided as per your plan. The route is being assigned and order is placed. Expenses is being added for a day.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={house} alt='' />
              <div className='second'></div>
            </div>
            <h4>Product Management</h4>
            <p>This software is allowing admin to add more products.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={payroll} alt='' />
              <div className='second'></div>
            </div>
            <h4>Client Retention</h4>
            <p>This software increases the client retention by 67% as products and services are being added and digital solution which is as per business needs.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={membership} alt='' />
              <div className='second'></div>
            </div>
            <h4>Design And User Experience</h4>
            <p>This allows operators to modify every aspect of user experience. This includes design, search, navigation. Different buyers see, interaction with different products and personalise the aspect of customer journey.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={pricing} alt='' />
              <div className='second'></div>
            </div>
            <h4>Product Inquiry</h4>
            <p>The product-based inquiry is listed in the Software. There is automated selling price calculation based on refined parameters. Maintaining customer wide selling price template. Item selection is based on export in picklist stage. Product expiry monitoring is updated in software. There are discounts based on product expiry. During the invoice processing, stock checking is there. Bill generation is through online.</p>
          </div>
        </div>
      </div>

      <div className='section-3'>
        <div className='container-3'>
          <div className='left'>
            <strong>We provide free demo
              <br />For services being offered</strong>
            <p>Fill the form so that we can help you to serve you better.</p>
          </div>
          <div className='right'>
            <div className='button'>Free Demo</div>
          </div>
        </div>
      </div>

      <div className='section-4'>
        <div className='left'>
          <h5>WHY CHOOSE US</h5>
          <h2>Let’s your company sales accelerate with our software</h2>
          <ul style={{ listStyle: 'none' }}>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Real time data access to Field Personal</h5></li>
            <p>Easily manage your field sales reps by scheduling their visits and using DM Tracker to track them in real-time and evaluate their performance. Manage all contracts in customer sales force automation software and receive orders instantly during all meetings.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Orders Management</h5></li>
            <p>Manage your orders in a sales force software that will store all your order history and allow you to process orders from a single dashboard in your sales force CRM system, also allowing you to add invoices and control all the payments.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Detailed Sales Analytics</h5></li>
            <p>Manage all your opportunities with a out of the box sales process that helps you successfully close every deal, guides you through every stage of the sales pipeline, and provides you with comprehensive analytics of your opportunities.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Compatible for iPhone and Android Mobile and Tablets</h5></li>
            <p>In today era, everyone has access to mobile and tablets. The software is being made to make compatible for iPhone and Android users and Tablets.</p>
          </ul>
        </div>
        <div className='right'>
            <img src={choose} alt=''/>
        </div>
      </div>

      {/* <div className='section-6'>
        <Slider/>
      </div> */}
    </div>
  )
}

export default Sales;