import React from 'react';
import css from "./Blog.module.scss"
import demo from "../../assets/image/react.png"

const Blog
 = () => {
  return (
    <>
    <section>
    <div className={css.heading}>Blog</div>
    <div className={css.container}>
      <section className={css.section1}>
        <img src={demo} />
      </section>
      <section className={css.section2}>
        <a href='/blog-info'>
        <h1 className={css.h1}>We can help you to get software which drives sales Smart Business solutions encompasses Web.</h1>
        </a>
        <p className={css.para}>We have found a simple solution to a peculiar problem. Finding the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand. Choose the best the indust ing the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand.  ry has to offer. A wide range of talent is available for hire at Reveation.</p>
      </section>
    </div>
    <div className={css.container}>
      <section className={css.section1}>
        <img src={demo} />
      </section>
      <section className={css.section2}>
        <h1 className={css.h1}>We can help you to get software which drives sales Smart Business solutions encompasses Web.</h1>
        <p className={css.para}>We have found a simple solution to a peculiar problem. Finding the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand. Choose the best the indust ing the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand.  ry has to offer. A wide range of talent is available for hire at Reveation.</p>
      </section>
    </div>  <div className={css.container}>
      <section className={css.section1}>
        <img src={demo} />
      </section>
      <section className={css.section2}>
        <h1 className={css.h1}>We can help you to get software which drives sales Smart Business solutions encompasses Web.</h1>
        <p className={css.para}>We have found a simple solution to a peculiar problem. Finding the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand. Choose the best the indust ing the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand.  ry has to offer. A wide range of talent is available for hire at Reveation.</p>
      </section>
    </div>  <div className={css.container}>
      <section className={css.section1}>
        <img src={demo} />
      </section>
      <section className={css.section2}>
        <h1 className={css.h1}>We can help you to get software which drives sales Smart Business solutions encompasses Web.</h1>
        <p className={css.para}>We have found a simple solution to a peculiar problem. Finding the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand. Choose the best the indust ing the right talent for your software project requirements is no more a task to lay down your precious time on. We do the hiring and management of the resources and you keep focus on better and bigger tasks at hand.  ry has to offer. A wide range of talent is available for hire at Reveation.</p>
      </section>
    </div>
    
    </section>
    
    
    
    </>
  )
}

export default Blog

// import React, { useState } from 'react';
// import css from './Blog.module.scss';
// import demo from '../../assets/image/react.png';

// const Blog = () => {
//   const [currentPage, setCurrentPage] = useState(1);
//   const blogsPerPage = 5;

//   const blogs = [
//     {
//       id: 1,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     },
//     {
//       id: 2,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 3,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 4,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 5,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 6,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 7,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 8,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 9,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 10,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     }, {
//       id: 11,
//       title: 'Blog 1 THIS IS BLOG OF REV',
//       content: 'Lorem ipsum dolor sit ametGH SJLFDHG   HIDSHGL  DSHGSL G SDDHGUISHG HIDSJLHGSD  SDDJGHJSDLG  SIDGHLS G HSJGLS G  HIDSDHG  HSD G...',
//       image: demo,
//     },
//     // Add more blogs here...
//   ];

//   const indexOfLastBlog = currentPage * blogsPerPage;
//   const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
//   const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

//   return (
//     <>
//       <section>
//         <div className={css.heading}>Blog</div>
//         <div className={css.container}>
//           {currentBlogs.map((blog) => (
//             <div key={blog.id} className={css.section}>
//               <img src={blog.image} alt="Blog Image" />
//               <a href={`/blog-info/${blog.id}`}>
//                 <h1 className={css.h1}>{blog.title}</h1>
//               </a>
//               <p className={css.para}>{blog.content}</p>
//             </div>
//           ))}
//         </div>
//         <div className={css.pagination}>
//   <button
//     disabled={currentPage === 1}
//     onClick={() => setCurrentPage(currentPage - 1)}
//   >
//     Previous
//   </button>
//   {Array(Math.ceil(blogs.length / blogsPerPage))
//     .fill()
//     .map((_, index) => (
//       <button
//         key={index}
//         className={currentPage === index + 1 ? css.active : ''}
//         onClick={() => setCurrentPage(index + 1)}
//       >
//         {index + 1}
//       </button>
//     ))}
//   <button
//     disabled={indexOfLastBlog >= blogs.length}
//     onClick={() => setCurrentPage(currentPage + 1)}
//   >
//     Next
//   </button>
// </div>

//       </section>
//     </>
//   );
// };

// export default Blog;
