import React from 'react';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import '../../Products/products.css';
import wellness from '../../../assets/image/wellness-center-management-solution.png';
import back from '../../../assets/image/15.png';
import manage from '../../../assets/logo/product-search.png';
import boarding from '../../../assets/logo/wish-list.png';
import consultation from '../../../assets/logo/product-management.png';
import auto from '../../../assets/logo/product-approval.png';
import Inventory from '../../../assets/logo/commission-management.png';
import house from '../../../assets/logo/inventory-managment.png';
import payroll from '../../../assets/logo/shipping-management.png';
import membership from '../../../assets/logo/order-management.png';
import pricing from '../../../assets/logo/compare-product.png';
import choose from '../../../assets/image/choose-us5.png';
import Slider from '../../../components/Slider/Slider';

function Wellness() {
  return (
    <div className='MainWellness'>
      <div className='section-1'>
        <h5>Wellness Center Management Solution</h5>
        <strong>Choose Reveation For Robust Wellness Programs</strong>
        <div className='thumb'>
          <img src={wellness} alt='' /></div>
      </div>

      <div className='section-2'>
        <div className='heading-2'>
          <strong>Wellness Centre Management Features</strong>
          <div className='dev'>
            <b className='devider1'></b>
            <b className='devider'></b>
          </div>
          <p>WCMS helps to streamline operations of wellness centre. This will allow centre to make improved and
            transparent operations including booking, billing, room assignments, room shuffling, guest health
            history management, Inventory management, housekeeping management etc. with a combination of web and
            mobile solutions.</p>
        </div>
        <div className='Cards'>
          <div className='Cardsmain' style={{ borderBottomColor: '#4551ff' }}>
            <div className='CardsImg'>
              <img src={manage} alt='' />
              <div className='second'></div>
            </div>
            <h4>Manage Guest Bookings</h4>
            <p>Manage personal details of a Guest like a name, address, date of birth, KYC etc. Manage bookings for their stay in the Resort or wellness centre. Manage Billing for the stay.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff2655' }}>
            <div className='CardsImg'>
              <img src={boarding} alt='' />
              <div className='second'></div>
            </div>
            <h4>Guest On Boarding & Checkout</h4>
            <p>Various products inventory inward and outward is managed seamlessly. Status on current inventory helps for future requisites to fulfil to have a hassle-free operations.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1495ff' }}>
            <div className='CardsImg'>
              <img src={consultation} alt='' />
              <div className='second'></div>
            </div>
            <h4>Consultation & Treatment</h4>
            <p>Doctor consultation details are managed through the system, enable users to see the history quickly to give right advice, treatments and diets.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#bc4cff' }}>
            <div className='CardsImg'>
              <img src={auto} alt='' />
              <div className='second'></div>
            </div>
            <h4>Auto Scheduling Daily Routines</h4>
            <p>As per the doctors advise, guest daily schedule has been defined including the activities to be done at which slot and with whom. Therapies, Diet along with therapist details are displayed on one click. Similar details can be made available to Guest for handy information on their own daily schedule on specially designed mobile app for them.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#ff8d00' }}>
            <div className='CardsImg'>
              <img src={Inventory} alt='' />
              <div className='second'></div>
            </div>
            <h4>Inventory Management</h4>
            <p>Various products inventory inward and outward is managed seamlessly. Status on current inventory helps for future requisites to fulfil to have a hassle-free operations.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1c7200' }}>
            <div className='CardsImg'>
              <img src={house} alt='' />
              <div className='second'></div>
            </div>
            <h4>House Keeping Management</h4>
            <p>Various Service request for housekeeping team can be captured through the mobile easily. Statuses of those service request can be tracked easily.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1c7200' }}>
            <div className='CardsImg'>
              <img src={payroll} alt='' />
              <div className='second'></div>
            </div>
            <h4>Payroll Management</h4>
            <p>Bid farewell to tedious excel sheets and more efficient way to manage the employee attendance. This helps to create reports for attendance and remote employees with cloud-based system and biometric system integration.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1c7200' }}>
            <div className='CardsImg'>
              <img src={membership} alt='' />
              <div className='second'></div>
            </div>
            <h4>Membership Plans</h4>
            <p>Manage pricing, discounts and mark-ups effortlessly with various promotions implemented time to time basis. Membership plans can be managed and tracked easily.</p>
          </div>
          <div className='Cardsmain' style={{ borderBottomColor: '#1c7200' }}>
            <div className='CardsImg'>
              <img src={pricing} alt='' />
              <div className='second'></div>
            </div>
            <h4>Pricing And Promotions</h4>
            <p>Manage pricing, discounts and mark-ups effortlessly across all the products and locations.</p>
          </div>
        </div>
      </div>

      <div className='section-3'>
        <div className='container-3'>
          <div className='left'>
            <strong>We provide free demo
              <br />For services being offered</strong>
            <p>Fill the form so that we can help you to serve you better.</p>
          </div>
          <div className='right'>
            <div className='button'>Free Demo</div>
          </div>
        </div>
      </div>

      <div className='section-4'>
        <div className='left'>
          <h5>WHY CHOOSE US</h5>
          <h2>Choose Us for wellbeing of your workplace</h2>
          <ul style={{ listStyle: 'none' }}>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Quick Access</h5></li>
            <p>One can access the information one needs quickly.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Get Rid with repetitive tasks</h5></li>
            <p>One can get the work done more efficiently by minimizing mundane tasks and focus on more productive work.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>Boost Employee Efficiency</h5></li>
            <p>Making available right information for right people easily increases employee efficiencies.</p>
            <li><CheckOutlinedIcon style={{ background: '#25285f', borderRadius: '50%', fontSize: 'large', color: '#fff' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <h5>More Accurate Information</h5></li>
            <p>Flawless information and data has been shared within various department without hassle.</p>

          </ul>
        </div>
        <div className='right'>
            <img src={choose} alt=''/>
        </div>
      </div>

      {/* <div className='section-6'>
        <Slider/>
      </div> */}
    </div>
  )
}

export default Wellness;