import React from 'react'
import css from "./Hire.module.scss"
import node from "../../../assets/image/Nodejs.svg"
import { Helmet } from 'react-helmet';

const NodeJS = () => {
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Hire Node.js Developer - Expertise in Web & Mobile App Development | YourCompany</title>
        <meta name="description" content="Looking to hire a Node.js developer for your web or mobile application development? Choose from our pool of talented professionals with years of experience in handling client requirements. Contact us now!" />
      </Helmet>
    <div>
        <div className={css.container}>
            <div className={css.section1}>
                <div className={css.title}>HIRE NODE JS DEVELOPER
</div>
                <div className={css.heading}>Choose an expert, intermediate or a novice as your project demands.
</div>
                <div className={css.text}>At Reveation, we have good pool of talented and skilled professionals where companies can choose from. These skilled professionals come with rich years of Experience in handling requirement of clients pertaining to web or mobile application development.

</div>
            </div>
            <div className={css.section2}>
<img src={node} alt="NodeJS developer" title="NodeJS developer" className={css.img} />
            </div>
        </div>
    </div></>
  )
}

export default NodeJS