import React from "react";
import "./contact.css";
import { Helmet } from "react-helmet";

import ContactI1 from "../../assets/image/contact-us.png";
import ContactForm from "./contactform";
import Contactcard from "./contactcard";

const Contact = () => {
  return (
    <>

<Helmet>
        <title>Contact Us - Reach Out to Our Software Development Experts</title>
        <meta name="description" content="Contact us to discuss your business requirements and let our exceptional team of software development experts take care of the rest. We provide services in software development, process automation, quality control, and more. Say hello to open up the dialogue and let Reveation serve you." />
      </Helmet>

      <div style={{ width: "100%", backgroundColor: "#f9f9f9" }}>
        <section className="contact-first ">
          <section className="contact-first-left">
            <h1 className="contact-heading">Contact Us</h1>
            <p className="contact-text">
              An exceptional team of software development experts are available
              to take your business requirements forward. Reach out to us with
              details of your needs and we will make sure we do the rest.
            </p>
          </section>
          <section className="contact-first-right">
            <img src={ContactI1} className="contact-img-1" alt="contact-us"/>
          </section>
        </section>
      </div>

      <section className="contact-second-main">
        <Contactcard />
      </section>

      <div>
        <section className="contact-third ">
          <section className="contact-third-left">
            <h1 className="contact-heading">Lets Connect</h1>
            <p className="contact-text">
              We provide all services in the field of software development,
              process automation, quality control, resource on hire and much
              more. Just say Hello to open up the Dialogue. We at Reveation will
              be happy to serve you.
            </p>
          </section>
          <section className="contact-third-right">
            <ContactForm />
          </section>
        </section>
      </div>
    </>
  );
};

export default Contact;
