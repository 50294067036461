import React from 'react';
import js from '../../../assets/logo/js.png';
import selenium from '../../../assets/logo/ic-Selenium.png';
import aws from '../../../assets/logo/ic-aws.png';
import jira from '../../../assets/logo/ic-jira.png';
import slack from '../../../assets/logo/ic-slack.png';
import burp from '../../../assets/logo/ic-burp_suite.png';
import jmeter from '../../../assets/logo/ic-JMeter.png';
import './Home4.css'

export default function Home4() {
  return (
    <div className='Home-4'>
                    <div className='border'>
                        <div className='middle'>
                            <p>//&nbsp;&nbsp; TECHNOLOGIES & TOOLS THAT WE DEAL IN &nbsp;&nbsp;//</p>
                            <strong>BUSINESS SOLUTIONS MADE SIMPLE.</strong>
                            <div className='button'>Know More About Reveation</div>
                        </div>
                    </div>
                    <div className='icons'>
                        <div className='integrationbox'>
                            <img src={selenium} alt='' />
                        </div>
                        <div className='integrationbox'>
                            <img src={aws} alt='' />
                        </div>
                        <div className='integrationbox'>
                            <img src={jira} alt='' />
                        </div>
                        <div className='integrationbox'>
                            <img src={slack} alt='' />
                        </div>
                        <div className='integrationbox'>
                            <img src={burp} alt='' />
                        </div>
                        <div className='integrationbox'>
                            <img src={jmeter} alt='' />
                        </div>
                    </div>
                </div>
  )
}
